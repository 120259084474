<template>
  <div class="row columns-14">
    <HeaderDashboard :description="$t('dashboard-message')" />
    <div class="main-content" :class="{ 'main-content--demo': demo }">
      <div class="h2 main-content__title">
        {{ $t("pick-a-lesson") }} <span>{{ $t("start-learning") }}</span>
      </div>

      <div class="global-loader global-loader--primary global-loader--relative" v-if="loading">
        <div class="loader"></div>
      </div>

      <div v-if="!loading && isNoPath">{{ $t("no-paths") }}</div>
      <div v-if="(!loading && isNoLessons) || (!loading && demo && demoLessons.length === 0)">
        {{ $t("no-lessons") }}
      </div>

      <vue-scroll
        class="main-scroll-dashboard"
        ref="vueScroll"
        v-if="showLessons"
        :ops="{
          scrollPanel: {
            scrollingX: false,
            scrollingY: true
          }
        }"
      >
        <div class="row main-scroll-area" :class="demo ? 'columns-13' : 'columns-10'">
          <div
            v-for="lesson in demo ? demoLessons : currentPath.lessons"
            :key="lesson.id"
            class="dashboard-content-column"
          >
            <LessonCard
              :title="lesson.title"
              :course="currentPath.title"
              :image-src="lesson.featureImageUrl"
              :key-phrases-count="lesson.keyPhrases"
              :questions-count="lesson.questions"
              :progress="getLessonProgress(lesson)"
              @click="startOrResume(lesson)"
              :attempt="lesson.attempt"
              :level="lesson.difficulty"
              :currentLesson="getCurrentLesson()"
              :lastCompletedLesson="getLastCompletedLesson()"
              :keyPhrasesActive="isKeyPhrasesActive(lesson)"
            />
          </div>
        </div>
      </vue-scroll>
    </div>
    <div class="dashboard-right" v-if="!demo">
      <div class="global-loader global-loader--primary" v-if="loading">
        <div class="loader"></div>
      </div>
      <DashboardRight
        v-if="showLessons"
        :lesson="getSidebarLesson()"
        :path="this.currentPath"
        v-bind="{ isStart, isFirst }"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import client from "../helpers/client";
import _ from "lodash";
import StartOrContinue from "../components/popups/StartOrContinue";
import WelcomePopup from "../components/popups/WelcomePopup";
import ChangePathPopup from "../components/popups/ChangePathPopup";
import introJsMixin from "../mixins/IntroJsMixin";
import LanguageChangeMixin from "../mixins/LanguageChangeMixin";

export default {
  name: "Home",
  metaInfo: {
    title: "Dashboard"
  },

  data() {
    return {
      isStart: false,
      isFirst: true,
      welcomePopupModalProps: {
        name: "welcomePopup",
        adaptive: true,
        scrollable: true,
        classes: "default-popup",
        height: "auto",
        width: "40%",
        minWidth: 350,
        clickToClose: false
      },
      lessonLevels: {
        beginner: "easy",
        intermediate: "medium",
        advanced: "hard"
      }
    };
  },

  mounted() {
    if (!this.demo) {
      this.defineHelpTours();
    }
  },

  props: {
    loading: Boolean
  },
  computed: {
    areSpecifiedLevelLessons() {
      return this.currentPath?.lessons.find(lesson => {
        return lesson.difficulty === this.demoLessonLevel;
      });
    },
    showLessons() {
      return !this.isNoPath && !this.isNoLessons;
    },

    isNoPath() {
      return !this.loading && _.isEmpty(this.paths);
    },

    isNoLessons() {
      const lessons = _.get(this.currentPath, "lessons");
      return !(!this.loading && !_.isEmpty(lessons));
    },

    demoLessons() {
      return this.currentPath.lessons.filter(lesson => lesson.difficulty === this.demoLessonLevel);
    },

    ...mapState("userStore", [
      "id",
      "currentPathId",
      "demo",
      "isIntroShown",
      "introJs",
      "showHelp",
      "lastAccess",
      "isWelcomePopupShown",
      "userLanguage"
    ]),
    ...mapState("lessonStore", ["demoLessonLevel"]),
    ...mapState("pathsStore", ["paths", "currentPath"])
  },
  watch: {
    demoLessonLevel() {
      const lessons = this.currentPath?.lessons;
      this.$set(this.currentPath, "lessons", lessons);
    },
    loading: {
      handler() {
        if (!this.demo && !this.isWelcomePopupShown) this.showFirstLoginPopup();
        if (this.currentPath && this.validateFinishedPathPopup()) this.showPathIsFinishedPopup();
        if (this.demo && !this.demoLessonLevel) this.showWelcomePopup();

        if (!this.demo) {
          this.$watch("showLessons", function() {
            this.scrollToCurrentLesson();
          });
        }
      }
    }
  },
  methods: {
    defineHelpTours() {
      const dashboardTour = {
        steps: [
          {
            element: document.querySelector(".main-content"),
            intro: `<p>${this.$t("lessons.intro")}</p>`
          }
        ]
      };
      const popupTour = {
        steps: [
          {
            element: document.querySelector(".popup"),
            intro: `
                    <h2>${this.$t("welcome-intro.header")}</h2>
                    <h4>${this.$t("welcome-intro.header2")}</h4>
                    <h4>${this.$t("welcome-intro.header3")}</h4>
                    <p>${this.$t("welcome-intro.paragraph")}</p>`
          }
        ]
      };

      const tours = [
        { key: "dashboard", data: dashboardTour },
        { key: "popup", data: popupTour }
      ];

      this.setTours(tours);
    },
    getLessonProgress(lesson) {
      return lesson.attempt ? lesson.attempt.progress : null;
    },

    getCurrentLesson() {
      const { currentLesson } = this.currentPath.attempt || {};
      return currentLesson;
    },

    getLastCompletedLesson() {
      const { completedLessons } = this.currentPath.attempt || {};
      return completedLessons ? completedLessons?.[completedLessons.length - 1] : null;
    },

    startOrResume(lesson) {
      if (lesson.attempt?.status === "started") {
        this.showModal(lesson);
      } else if (lesson.attempt?.status === "finished") {
        this.continueLesson({ lesson });
      } else {
        this.startNewLesson(lesson);
      }
    },

    showModal(lesson) {
      this.$modal.show(
        StartOrContinue,
        { lesson },
        {
          name: "startOrResume",
          adaptive: true,
          scrollable: true,
          classes: "default-popup",
          height: "auto",
          width: "35%",
          minWidth: 350
        }
      );
    },
    showWelcomePopup() {
      this.$modal.show(
        WelcomePopup,
        {
          demo: true,
          header: this.$t("welcome.title"),
          description: this.$t("welcome.desc"),
          buttons: this.lessonLevels
        },
        this.welcomePopupModalProps,
        {
          opened: this.startModalIntroJs,
          closed: this.introJsFunction
        }
      );
    },
    showFirstLoginPopup() {
      this.$modal.show(
        WelcomePopup,
        {
          demo: false,
          header: this.$t("first-login.title"),
          description: this.$t("first-login.title"),
          buttons: {
            firstButton: "lets-get-started"
          }
        },
        this.welcomePopupModalProps,
        {
          closed: async () => {
            await client.post(`api/user/${this.id}/welcome-popup-shown`, {
              user_id: this.id
            });
            this.introJsFunction();
          }
        }
      );
    },
    showPathIsFinishedPopup() {
      this.$modal.show(
        ChangePathPopup,
        {
          header: this.$t("finished-lessons-header"),
          description: this.$t("finished-lessons-title")
        },
        this.welcomePopupModalProps,
        {
          opened: async () => {
            const {
              id,
              attempt: { uuid }
            } = this.currentPath;

            this.setPathAttemptProperty({ key: "isPopupShown", value: true });

            await client.post(`api/paths/${id}/attempt/${uuid}/mark-finish-popup`, {
              markPopup: true
            });
          }
        }
      );
    },
    startNewLesson(lesson) {
      const pathAttempt = this.currentPath.attempt?.uuid;

      if (!pathAttempt) {
        this.newPathAttempt({ path: this.currentPath }).then(uuid => {
          this.setPathAttemptProperty({ key: "uuid", value: uuid });

          this.startNewLessonRequest(lesson);
        });
      } else {
        this.startNewLessonRequest(lesson);
      }
    },

    startNewLessonRequest(lesson) {
      const pathAttemptUuid = this.currentPath.attempt?.uuid;
      if (pathAttemptUuid) {
        this.newLesson({
          lesson: lesson,
          pathAttemptUuid: pathAttemptUuid
        });
      }
    },

    getSidebarLesson() {
      const lastAttempt = this.currentPath.attempt;
      const { currentLesson } = lastAttempt || {};

      this.isStartNewLesson(lastAttempt);
      this.isFirstLesson(lastAttempt);
      if (!currentLesson) {
        const nextLesson = _.filter(this.currentPath.lessons, lesson => lesson.attempt.status !== "finished");

        if (nextLesson) {
          return nextLesson[0];
        }
        //todo: if all lessons completed
      }

      return _.find(this.currentPath.lessons, lesson => lesson.attempt.uuid === currentLesson);
    },

    isStartNewLesson(lastAttempt) {
      const { currentLesson } = lastAttempt || {};
      this.isStart = _.isEmpty(currentLesson);
    },

    isFirstLesson(lastAttempt) {
      const { completedLessons } = lastAttempt || {};
      this.isFirst = _.isEmpty(completedLessons);
    },
    startModalIntroJs() {
      this.defineHelpTours();
      this.showIntroFirstTime("popup");
    },
    introJsFunction() {
      this.showIntroFirstTime("dashboard");
    },
    scrollToCurrentLesson() {
      if (document.getElementById("current")) {
        this.$refs.vueScroll.scrollIntoView("#current");
        this.$refs.vueScroll.scrollTo({ x: "-300" });
      }
    },
    validateFinishedPathPopup() {
      return (
        this.currentPath.attempt &&
        this.currentPath.attempt.completedLessons?.length === this.currentPath.lessons.length &&
        !this.currentPath.attempt?.isPopupShown
      );
    },
    isKeyPhrasesActive(lesson) {
      return lesson.module_states?.key_phrases;
    },

    ...mapActions("lessonStore", ["newLesson", "redirectToLesson", "newPathAttempt", "continueLesson"]),
    ...mapMutations("userStore", ["setIntroJs", "setTours"]),
    ...mapMutations("pathsStore", ["setPathAttemptProperty"])
  },
  mixins: [introJsMixin, LanguageChangeMixin]
};
</script>
