<template>
  <div class="popup">
    <div class="h2 popup__title">{{ lesson.title }}</div>
    <div class="popup__desc">
      {{ $t("start-or-continue") }}
    </div>
    <div class="popup__buttons">
      <div class="popup__item">
        <InputButton
          :text="$t('start-new-lesson')"
          action="button"
          @click="startNewLesson"
          :loading="lessonBtnsDisabled"
        ></InputButton>
      </div>
      <div class="popup__item">
        <InputButton
          :text="$t('continue-lesson')"
          action="button"
          @click="continueLesson"
          :loading="lessonBtnsDisabled"
        ></InputButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "StartOrContinue",

  props: {
    lesson: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      uuid: null,
      lessonBtnsDisabled: false
    };
  },

  methods: {
    async startNewLesson() {
      this.lessonBtnsDisabled = true;
      await this.newLesson({
        lesson: this.lesson,
        pathAttemptUuid: this.lesson.attempt.pathAttemptUuid
      });
      this.$modal.hide("startOrResume");
      this.lessonBtnsDisabled = false;
    },

    async continueLesson() {
      this.lessonBtnsDisabled = true;
      await this.resumeLesson({ lesson: this.lesson });
      this.$modal.hide("startOrResume");
      this.lessonBtnsDisabled = false;
    },

    ...mapActions("lessonStore", ["newLesson", "redirectToLesson", "resumeLesson"])
  }
};
</script>
